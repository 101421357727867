<template>
  <b-col sm="12">
    <b-card>
      <b-form
        class="col-sm-5 mx-auto"
        @submit="onSubmit"
        @reset="onReset"
        v-if="show"
      >
        <b-form-group
          id="input-group-1"
          label="نام و نام خانوادگی :"
          label-for="fullname"
        >
          <b-form-input
            id="fullname"
            v-model="form.fullname"
            type="text"
            placeholder="وارد کنید"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="راننده :" label-for="chooseDriver">
          <b-form-select
            id="chooseDriver"
            v-model="selected"
            @change="showDriver()"
            :options="options"
          />
        </b-form-group>

        <b-list-group class="mt-1" v-if="driverDetail">
          <b-list-group-item>نوع : سواری</b-list-group-item>
          <b-list-group-item>برند : ایران خودرو</b-list-group-item>
          <b-list-group-item>مدل : 1399</b-list-group-item>
          <b-list-group-item>میزان کارکرد : 100000</b-list-group-item>
          <b-list-group-item>پلاک : 25 ه 685</b-list-group-item>
          <b-list-group-item>رنگ : مشکی</b-list-group-item>
        </b-list-group>

        <b-form-group label="استعلام خودرو :" label-for="InquiryCar">
          <b-form-select
            id="InquiryCar"
            v-model="selectedInquiryCar"
            :options="optionsInquiryCar"
          />
        </b-form-group>
        <b-form-group label="استعلام راننده :" label-for="InquiryDriver">
          <b-form-select
            id="InquiryDriver"
            v-model="selectedInquiryDriver"
            :options="optionsInquiryDriver"
          />
        </b-form-group>

        <b-form-group id="input-group-2" label="امتیاز :" label-for="rate">
          <b-form-input
            id="rate"
            v-model="form.rate"
            type="number"
            placeholder="وارد کنید"
          ></b-form-input>
        </b-form-group>

        <b-button
          type="button"
          class="mt-1"
          variant="primary"
          @click.prevent="
            pdfDownload('https://rent.yekibedo.ir/uploads/contract.pdf')
          "
          >دانلود PDF این قرارداد</b-button
        >
        <b-button type="reset" variant="danger ml-1 mt-1">پاک کردن</b-button>
      </b-form>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      selected: null,
      selectedInquiryCar: null,
      selectedInquiryDriver: null,
      options: [
        { value: null, text: "لطفا انتخاب کنید" },
        { value: "a", text: "راننده اول" },
        { value: "b", text: "راننده دوم" },
        { value: "c", text: "راننده سوم" },
      ],
      optionsInquiryCar: [
        { value: null, text: "لطفا انتخاب کنید" },
        { value: "yes", text: "دارد" },
        { value: "no", text: "ندارد" },
      ],
      optionsInquiryDriver: [
        { value: null, text: "لطفا انتخاب کنید" },
        { value: "yes", text: "دارد" },
        { value: "no", text: "ندارد" },
      ],
      form: {
        fullname: "",
        rate: "",
      },
      show: true,
      driverDetail: false,
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      var data = JSON.stringify(this.form);
      //   console.log(data);
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.fullname = "";
      this.form.rate = "";
      this.selected = null;
      this.selectedInquiryCar = null;
      this.selectedInquiryDriver = null;
      this.driverDetail = false;
    },
    pdfDownload(url) {
      window.open(url, "_blank");
    },
    showDriver() {
      if (this.selected != null) {
        this.driverDetail = true;
      } else {
        this.driverDetail = false;
      }
    },
  },
};
</script>